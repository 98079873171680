<template>
  <div id="app" class="active" :class="{ enlarged: !showNav }" :style="isFullWidth ? { width: '100%' } : {}">
    <component
      v-if="showLayout"
      :is="layout"
      :class="[restyle_class]"
      :has-menu="hasMenu"
    >
      <router-view class="page-container" />
    </component>
    <ServerErrorModal v-if="serverError" @close="clearServerError()"/>
    <restyle_alert :alerts="alerts" :darkTheme="darkTheme" :darkThemeRoutes="darkThemeRoutes" v-if="alerts.length > 0" />
    <resultCompleteTask v-if="isVisibleModalOMReport" @close="setIsVisibleModalOMReport(false)"  />
    <userNotifications v-if="isVisibleNotificationsPopup" />
    <RestyleTooltip 
      v-if="view_tooltip"
      v-bind="view_tooltip"
    />
  </div>
</template>

<script>
// Login и ForgotPassword не трогать, решают баг с мельканием сайдбара при загрузке страницы.
import Login from "../src/pages/Auth/Login";
import ForgotPassword from "../src/pages/Auth/ForgotPassword.vue";
import ServerErrorModal from '@/components/error/ServerErrorModal.vue';
import { mapState, mapGetters, mapMutations } from "vuex";
const default_layout = "default";
import resultCompleteTask from '@/components/OnlineMonitoring/statistic/resultCompleteTask.vue';
import userNotifications from "@/components/restyle/popup/userNotifications.vue";
import { wbConnect } from "@/_helpers/wb-connect.js";

export default {
  name: "App",
  // Login и ForgotPassword не трогать, решают баг с мельканием сайдбара при загрузке страницы.
  components: {
    Login,
    ForgotPassword,
    ServerErrorModal,
    resultCompleteTask,
    userNotifications
  },
  data() {
    return {
      showLayout: false,
      darkTheme: false,
      darkThemeRoutes: [
        'forgot-password',
        'reset-password',
        'login',
      ],
      isFullWidth: false,
    }
  },
  computed: {
    restyle_class() {
      // let result = ['login'].includes(this.$route.name)? '': 'page d-flex flex-column justify-content-between';
      let result = '';
      return result
    },
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
    hasMenu() {
      return this.layout === "no-sidebar-layout";
    },
    ...mapState(["showNav", "userMenu"]),
    ...mapGetters({
      userCan: "user/userCan",
      allRoles: "roles/allRoles",
      rolesSRP: "roles/roles_SRP",
      user: "user/user",
      auth: "auth/auth",
      alerts: "alert/get_alerts",
      serverError: "error/serverError",
      view_tooltip: "tooltip_store/GET_VIEW_TOOLTIP",
      isVisibleModalOMReport: "online_monitoring/get_is_visible_modal_om_report",
      isVisibleNotificationsPopup: "user_notifications/GET_IS_VISIBLE_NOTIFICATIONS_POPUP",
    }),
  },
  watch: {
    $route(to) {
      //showLayout решает баг с мельканием default layout-a при загрузке страницы
      if (!this.showLayout) {
        this.showLayout = true;
      }
      if (this.userMenu) this.$store.dispatch("close_user_menu");
      document.body.classList.remove("has-modal");

      if (!this.alerts.some((alert) => alert.sticky)) {
        this.$store.dispatch("alert/clear");
      }
      if (this.darkThemeRoutes.includes(to.name)) {
        this.darkTheme = true;
      } else {
        this.darkTheme = false;
      }

      document.title = to.meta.title == "Мобильные приложения для Android" ? "SmartRO" : to.meta.title;

      if (to.name === 'remove-account' || to.name === 'remove-account-confirm') {
        this.isFullWidth = true;
      } else {
        this.isFullWidth = false;
      }
    },
    user(newValue) {
      if (newValue?.id) {
        this.$store.dispatch("hotspots/getListActiveHotSpots")
        this.wbConnected()
      }
    }
  },
  beforeCreate() {
    let auth = localStorage.getItem("auth_token") !== null;
    if (!auth) {
      const publicPages = ["login", "forgot-password", "reset-password", "boss-app", "test_boss", "remove-account", "confirm"];
      const pathname = window.location.pathname.slice(1);
      const authRequired = !publicPages.some(page => pathname.startsWith(page));
      if (authRequired) {
        this.$router.push({
          name: "login",
          query: {
            redirect: window.location.pathname + window.location.search,
          },
        });
      }
    }
  },
  created() {
    if (this.auth) {
      if (this.userCan("role_read_all")) {
        this.$store.dispatch("roles/getRoles")
      };
      if (this.userCan("role_read_all")) {
        this.$store.dispatch("roles/getRolesSRP")
      };

      if (this.user?.id) {
        this.wbConnected()
      }
    }
    
    if (this.user?.id) {
      this.$store.dispatch("hotspots/getListActiveHotSpots")
    }
  },
  methods: {
    ...mapMutations({
      setIsVisibleModalOMReport: "online_monitoring/set_is_visible_modal_om_report",
    }),
    clearServerError() {
      this.$store.dispatch('error/clearServerError');
    },
    wbConnected() {
      const userID = JSON.parse(localStorage.getItem('user')).id;
      const wbUrlChannel = `notification-${userID}`
      const wbEventName = 'NotificationEvent'

      if (!this.$store.state.wbConnect.echo) {
        wbConnect().echoConnect();
      }

      if (this.$store.state.wbConnect.echo) {
        wbConnect().echoOpenChannel(wbUrlChannel, wbEventName)

        document.addEventListener(wbUrlChannel, (event) => {
          this.$store.commit('user_notifications/SET_LIST_NOTIFICATIONS', event.detail);
        });
      }
    }
  }
}; 
</script>

<style lang="scss"> 
@import "./assets/style/_base.scss";

</style>